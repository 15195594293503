import React, { useState, useEffect } from 'react'
import CrudTable from '../../crud/CrudTable'
import {
    carService as service,
    cartypeService,
    deviceService,
} from '../../../services'
import styled from 'styled-components'
import LockIcon from '@mui/icons-material/Lock'
import LockOpenIcon from '@mui/icons-material/LockOpen'
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation'
import { APIProvider, Map, Marker } from '@vis.gl/react-google-maps'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'
import { Box, Button, Modal } from '@mui/material'
import { useTranslation } from 'react-i18next'
import BatteryChargingFullIcon from '@mui/icons-material/BatteryChargingFull'
import Battery4BarIcon from '@mui/icons-material/Battery4Bar'
import BatteryAlertIcon from '@mui/icons-material/BatteryAlert'
import KeyOffIcon from '@mui/icons-material/KeyOff'
import KeyIcon from '@mui/icons-material/Key'
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew'
import DriveEtaIcon from '@mui/icons-material/DriveEta'
import { ToastContainer, toast } from 'react-toastify'
import Moment from 'react-moment'

const initFilter = {
    number: null,
    alias: null,
    brand: null,
    model: null,
    cardType: null,
    device: null,
    statusCar: '',
    door_status: null,
    fuel_from: null,
    fuel_to: null,
    enabled: -1,
}

export default function Car() {
    const { t, i18n } = useTranslation()
    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '80%',
        maxWidth: '80%',
        height: '80vh',
        bgcolor: 'white',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    }

    const [point, setPoint] = useState<any>(null)
    const [openModal, setOpenModal] = useState<any>(null)

    const createEstructureFilter = [
        {
            type: 'input',
            name: 'number',
            col: 4,
        },
        {
            type: 'input',
            name: 'alias',
            col: 4,
        },
        {
            type: 'input',
            name: 'brand',
            col: 4,
        },
        {
            type: 'input',
            name: 'model',
            col: 4,
        },
        {
            type: 'autocomplete',
            name: 'cardType',
            service: cartypeService.combo,
            col: 4,
        },
        {
            type: 'autocomplete',
            name: 'device',
            service: deviceService.combo,
            col: 4,
        },
        {
            type: 'autocomplete',
            name: 'statusCar',
            options: [
                { key: '', name: '' },
                { key: 'FREE', name: 'Libre' },
                { key: 'BLOCK', name: 'Bloqueado' },
                { key: 'RESERVED', name: 'Reserbado' },
                { key: 'INSHARE', name: 'Share activo' },
                { key: 'INRENT', name: 'Rent activo' },
            ],
            col: 4,
        },
        {
            type: 'input',
            name: 'door_status',
            inputtype: 'number',
            col: 4,
        },
        {
            type: 'input',
            name: 'fuel_from',
            inputtype: 'number',
            col: 4,
        },
        {
            type: 'input',
            name: 'fuel_to',
            inputtype: 'number',
            col: 4,
        },
        {
            type: 'autocomplete',
            name: 'enabled',
            options: [
                { key: -1, name: 'Todos' },
                { key: 0, name: 'Deshabilitado' },
                { key: 1, name: 'Habilitado' },
            ],
            col: 4,
        },
    ]

    const closeCar = (id) => {
        service.closedoor(id, (data, error) => {
            if (data && data.data) {
                toast.success('Se ha enviado la acción', {
                    position: toast.POSITION.TOP_CENTER,
                })
            }
        })
    }

    const openCar = (id) => {
        service.opendoor(id, (data, error) => {
            if (data && data.data) {
                toast.success('Se ha enviado la acción', {
                    position: toast.POSITION.TOP_CENTER,
                })
            }
        })
    }

    const lockCar = (id) => {
        service.lock(id, (data, error) => {
            if (data && data.data) {
                toast.success('Se ha enviado la acción', {
                    position: toast.POSITION.TOP_CENTER,
                })
            }
        })
    }

    const unlockCar = (id) => {
        service.unlock(id, (data, error) => {
            if (data && data.data) {
                toast.success('Se ha enviado la acción', {
                    position: toast.POSITION.TOP_CENTER,
                })
            }
        })
    }

    const paintCell = {
        tiempos: (value, object) => {
            return (
                <>
                    <p>
                        <b>Parado desde:</b>{' '}
                        <span>
                            {object['stopFrom'] ? (
                                <Moment
                                    date={object['stopFrom']}
                                    format={'DD/MM/yyyy HH:mm:ss'}
                                />
                            ) : (
                                ''
                            )}
                        </span>
                    </p>
                    <p>
                        <b>Último share:</b>{' '}
                        <span>
                            {object['lastShare'] ? (
                                <Moment
                                    date={object['lastShare']}
                                    format={'DD/MM/yyyy HH:mm:ss'}
                                />
                            ) : (
                                ''
                            )}
                        </span>
                    </p>
                    <p>
                        <b>Último rent:</b>{' '}
                        <span>
                            {object['lastRent'] ? (
                                <Moment
                                    date={object['lastRent']}
                                    format={'DD/MM/yyyy HH:mm:ss'}
                                />
                            ) : (
                                ''
                            )}
                        </span>
                    </p>
                </>
            )
        },
        fuelPercent: (value, object) => {
            if (value != null) {
                return (
                    <span
                        style={{
                            display: 'flex',
                            width: '100%',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        {(value < 10 ? '0' : '') + value.toFixed(2)}%{' '}
                        {value > 50 ? (
                            <LocalGasStationIcon style={{ color: 'green' }} />
                        ) : value > 30 ? (
                            <LocalGasStationIcon style={{ color: 'orange' }} />
                        ) : (
                            <LocalGasStationIcon style={{ color: 'red' }} />
                        )}
                    </span>
                )
            } else {
                return <></>
            }
        },
        batery: (value, object) => {
            if (value != null) {
                return (
                    <span
                        style={{
                            display: 'flex',
                            width: '100%',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        {value.toFixed(2)}
                        {value > 12.3 ? (
                            <BatteryChargingFullIcon
                                style={{ color: 'green' }}
                            />
                        ) : value > 12 ? (
                            <Battery4BarIcon style={{ color: 'orange' }} />
                        ) : (
                            <BatteryAlertIcon style={{ color: 'red' }} />
                        )}
                    </span>
                )
            } else {
                return <></>
            }
        },
        move_status: (value, object) => {
            if (value && value > 0) {
                return <DriveEtaIcon style={{ color: 'green' }} />
            } else {
                return <DriveEtaIcon style={{ color: 'red' }} />
            }
        },
        engine_status: (value, object) => {
            if (value && value > 0) {
                return <PowerSettingsNewIcon style={{ color: 'green' }} />
            } else {
                return <PowerSettingsNewIcon style={{ color: 'red' }} />
            }
        },
        door_status: (value, object) => {
            if (value && value == 1) {
                return (
                    <BtnTable onClick={() => openCar(object['id'])}>
                        <LockIcon style={{ color: 'green' }} />
                    </BtnTable>
                )
            } else {
                return (
                    <BtnTable onClick={() => closeCar(object['id'])}>
                        <LockOpenIcon style={{ color: 'red' }} />
                    </BtnTable>
                )
            }
        },
        autonomia: (value, object) => {
            if (value) {
                return <>{value.toFixed(2)} </>
            } else {
                return <></>
            }
        },
        speed: (value, object) => {
            return <>{value}</>
        },
        statusCar: (value, object) => {
            if (value == 'FREE') {
                return <span style={{ color: 'green' }}>Libre</span>
            } else if (value == 'BLOCK') {
                return <span style={{ color: 'black' }}>Bloqueado</span>
            } else if (value == 'RESERVED') {
                return <span style={{ color: 'gray' }}>Reservado</span>
            } else if (value == 'INSHARE') {
                return <span style={{ color: 'red' }}>Share activo</span>
            } else if (value == 'INRENT') {
                return <span style={{ color: 'red' }}>Rent activo</span>
            } else {
                return <></>
            }
        },
        blockCar: (value, object) => {
            if (value) {
                return (
                    <BtnTable onClick={() => unlockCar(object['id'])}>
                        <KeyOffIcon style={{ color: 'red' }} />
                    </BtnTable>
                )
            } else {
                return (
                    <BtnTable onClick={() => lockCar(object['id'])}>
                        <KeyIcon style={{ color: 'green' }} />
                    </BtnTable>
                )
            }
        },
    }
    const handleDownload = (d) => {
        setPoint(d)
    }

    useEffect(() => {
        if (point?.id) {
            setOpenModal(true)
        }
    }, [point])

    const handleCloseModal = () => {
        setOpenModal(false)
        setPoint(null)
    }

    return (
        <CrudTable
            service={service}
            urledit={'/car/edit/'}
            urlnew={'/car/new/'}
            filter={initFilter}
            i18n={'car'}
            rowsPerPage={20}
            paintCell={paintCell}
            order={true}
            orderColumns={{
                number: 'number',
                alias: 'alias',
                'brand|model': 'brand',
                statusCar: 'statusCar',
                engine_status: 'engineStatus',
                move_status: 'speed',
                door_status: 'doorStatus',
                fuelPercent: 'fuel_level_percent',
                fuel_level: 'fuel_level',
                batery: 'batery',
                blockCar: 'blockCar',
                km: 'km',
            }}
            fieldstable={[
                'picture',
                'number',
                'alias',
                'brand|model',
                'statusCar',
                'move_status',
                'engine_status',
                'door_status',
                'blockCar',
                'batery',
                //'fuel_capacity',
                //'fuel_level',
                'fuelPercent',
                'autonomia',
                //'enabled',
                'km',
                //'speed',
                'tiempos',
            ]}
            exportfields={[
                'number',
                'alias',
                'brand',
                'model',
                'statusCar',
                'enabled',
                'km',
                'speed',
            ]}
            labels={{ 'brand|model': t('car.field.brand'), tiempos: 'Times' }}
            estructureForm={createEstructureFilter}
            moreActions={[
                { handle: handleDownload, icon: <RemoveRedEyeIcon /> },
            ]}
        >
            <>
                <ToastContainer />
                <Modal
                    open={openModal}
                    onClose={handleCloseModal}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    <Box sx={style}>
                        {point && point.lat && point.lng && (
                            <APIProvider apiKey={process.env.REACT_APP_GMAPS!}>
                                <Map
                                    center={{ lat: point.lat, lng: point.lng }}
                                    zoom={18}
                                    style={{
                                        width: '100%',
                                        height: 'calc( 100% - 100px )',
                                    }}
                                    styles={[
                                        {
                                            featureType: 'poi',
                                            stylers: [{ visibility: 'off' }],
                                        },
                                    ]}
                                >
                                    <Marker
                                        position={{
                                            lat: point.lat,
                                            lng: point.lng,
                                        }}
                                        title={point.number}
                                        icon={'/marker.png'}
                                    />
                                </Map>
                            </APIProvider>
                        )}
                        <div style={{ marginTop: '10px', textAlign: 'center' }}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => handleCloseModal()}
                            >
                                {' '}
                                Cerrar
                            </Button>
                        </div>
                    </Box>
                </Modal>
            </>
        </CrudTable>
    )
}

const Labelok = styled.span`
    background: #83c63b;
    padding: 0.3em 1em;
    border-radius: 3px;
    color: white;
    display: inline-block;
    min-width: 130px;
    text-align: center;
`
const Labelko = styled.span`
    background: red;
    padding: 0.3em 1em;
    border-radius: 3px;
    color: white;
    display: inline-block;
    min-width: 130px;
    text-align: center;
`

const BtnTable = styled.button`
    background: transparent;
    border: none;
    pointer: cursor;
`
